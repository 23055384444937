import type { SubscriptionStepUpdateDescription } from '@orus.eu/backend/src/routers/self-onboarding'
import type { SubscriptionStepValidationIssue } from '@orus.eu/backend/src/services/subscription/subscription-service'
import {
  getCommonClientAttributes,
  subscriptionUiStepsById,
  subscriptionUiStepsIndicesById,
  type LooselyTypedData,
  type SubscriptionBreadcrumb,
  type SubscriptionDetail,
  type SubscriptionStepId,
} from '@orus.eu/dimensions'
import { TechnicalError, UnreachableCaseError, checkDefinedAndNotNull, ensureError } from '@orus.eu/error'
import { newSubscribable } from '@orus.eu/observable'
import type { OperatingZone } from '@orus.eu/operating-zone'
import {
  FunctionnalProblemError,
  NotFoundError,
  useAsyncCallback,
  useCrash,
  useOpenChat,
  useSetDefaultLanguage,
} from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { useNavigate, useSearch } from '@tanstack/react-router'
import deepEqual from 'deep-equal'
import { memo, useCallback, useEffect, useState, type FormEvent } from 'react'
import { trpc, trpcReact } from '../../../client'
import { useSetEmbeddingPartner } from '../../../lib/embedding-partner'
import { ensureGTMLoaded } from '../../../lib/gtm'
import { sendHotjarEvent, setHotjarIdentify } from '../../../lib/hotjar-util'
import { sendMessage } from '../../../lib/tracking/tracking'
import { useBuildUrl } from '../../../use-build-url'
import { useModularNextEnabled } from './modular-next-enabled'
import { getPageCategory } from './subscription-page-category'
import { SubscriptionV2StepPageContent } from './subscription-v2-step-page-content'
import { useHandleExclusionProblem } from './use-handle-exclusion-problem'
import { useSinglettonState } from './use-singleton-state'

type SubscriptionV2StepPageLoaderProps = {
  /**
   * The subscription we want to be on. May be different from the actually displayed subscription during the
   * transition after an url change.
   */
  requestedSubscriptionId: string
  /**
   * The step we want to be on. May be different from the actually displayed subscription during the
   * transition after an url change.
   */
  requestedStepId: SubscriptionStepId
  /**
   * Identifies a detail to focus on, such as a guarantee while on the quote step
   */
  detail?: SubscriptionDetail
}

const LOADING = { type: 'loading' } as const

const localChangesSingleton = newSubscribable<LooselyTypedData>({})
const updateDescriptionSingleton = newSubscribable<
  (SubscriptionStepUpdateDescription & { type: 'nominal' }) | { type: 'loading' }
>(LOADING)

/**
 * This component is responsible for loading the data and managing the state of a subscription step.
 * Also see SubscriptionV2StepPageContent for layout
 */
export const SubscriptionV2StepPageLoader = memo<SubscriptionV2StepPageLoaderProps>(
  function SubscriptionV2StepPageLoader({ requestedSubscriptionId, requestedStepId, detail }) {
    const setDefaultLanguage = useSetDefaultLanguage()
    const urlSearchParams = useSearch({ strict: false })
    const { buildUrl } = useBuildUrl()
    const isEmbedInIframe = urlSearchParams.embedInIframe
    const navigate = useNavigate()
    const crash = useCrash()
    const handleExclusionProblem = useHandleExclusionProblem()
    /**
     * Changes applied locally by the client
     */
    const [localChanges, setLocalChanges] = useSinglettonState(localChangesSingleton)
    const { nextEnabledByAllContributions, registerNextEnabledContribution } = useModularNextEnabled()
    const [isLoadingWhileTryCompleteStep, setIsLoadingWhileTryCompleteStep] = useState(false)
    const [completeStepTimeoutId, setCompleteStepTimeoutId] = useState<NodeJS.Timeout | null>(null)
    const [cancelCompleteStepRetry, setCancelCompleteStepRetry] = useState(false)
    /**
     * State displayed in the UI with the last result computed from the local changes
     */
    const localStateResult = useLocalState(requestedSubscriptionId, requestedStepId, localChanges)

    useEffect(() => {
      ;(async () => {
        const result = await trpc.selfOnboarding.getStepTrackingParams.query(requestedSubscriptionId)
        if (isFailure(result)) {
          switch (result.problem.type) {
            case 'subscription-unavailable':
            case 'no-subscription-found':
              // nothing to do here, the main api call will result in an error message informing the
              // use of the situation
              return
          }
        }
        const { activity, riskCarrierProducts } = result.output
        const uiStep = subscriptionUiStepsById[requestedStepId]
        sendMessage({
          event: 'form_step_loaded',
          subscription_id: requestedSubscriptionId,
          activity_name: activity ? activity.displayName : '',
          page_category: getPageCategory(uiStep.id),
          step_name: requestedStepId,
          step_number: subscriptionUiStepsIndicesById[requestedStepId] + 1,
          policy_type: riskCarrierProducts ? riskCarrierProducts.join(',') : '',
          ...getCommonClientAttributes(riskCarrierProducts),
        })
      })().catch((err: unknown) => {
        crash(ensureError(err))
      })
    }, [crash, requestedStepId, requestedSubscriptionId])

    const updateLocalChanges = useCallback(
      (newChanges: LooselyTypedData) => {
        setLocalChanges((currentChanges: LooselyTypedData) => ({ ...currentChanges, ...newChanges }))
      },
      [setLocalChanges],
    )

    const updateAndPersistLocalChanges = useCallback(
      (newChanges: LooselyTypedData) => {
        setLocalChanges((currentChanges: LooselyTypedData) => ({ ...currentChanges, ...newChanges }))
        trpc.selfOnboarding.updateStepSubscription
          .mutate({
            subscriptionId: requestedSubscriptionId,
            stepId: requestedStepId,
            changes: newChanges,
          })
          .catch((err) => {
            crash(ensureError(err))
          })
      },
      [crash, requestedStepId, requestedSubscriptionId, setLocalChanges],
    )

    const { mutateAsync: completeStepMutate, isPending: isCompleteStepLoading } =
      trpcReact.selfOnboarding.completeStep.useMutation({})

    useEffect(() => {
      if (isCompleteStepLoading) {
        setIsLoadingWhileTryCompleteStep(true)
      }
    }, [isCompleteStepLoading, setIsLoadingWhileTryCompleteStep])

    useEffect(() => {
      if (localStateResult.type === 'loading') return
      setHotjarIdentify(localStateResult.subscriptionId, localStateResult.localState)
      sendHotjarEvent(localStateResult.stepId)
    }, [localStateResult])

    const operatingZone = localStateResult.type === 'loading' ? undefined : localStateResult.operatingZone

    const handleSubmit = useAsyncCallback(
      /**
       * @param event the original event from the form, if any
       * @param additionalChanges additionnal changes that need to be submitted. Not the primary way
       * of updating data, but needed for actions that change data and submit the form at the same time
       */
      async (event?: FormEvent, additionalChanges?: LooselyTypedData) => {
        event?.preventDefault()

        if (localStateResult.type === 'loading' || localStateResult.serverCallPending) {
          if (!isLoadingWhileTryCompleteStep) setIsLoadingWhileTryCompleteStep(true)
          return
        }

        setCancelCompleteStepRetry(true)
        if (completeStepTimeoutId) {
          clearTimeout(completeStepTimeoutId)
          setCompleteStepTimeoutId(null)
        }

        const { subscriptionId, stepId } = localStateResult

        const changes = { ...localChanges, ...additionalChanges }

        const result = await completeStepMutate({ subscriptionId, stepId, changes })
        if (isFailure(result)) {
          switch (result.problem.type) {
            case 'exclusion': {
              handleExclusionProblem({
                ...result.problem,
                operatingZone: checkDefinedAndNotNull(operatingZone),
                stepId,
              })
              return
            }
            case 'invalid-step':
            case 'invalid-subscription-step-input':
              // Since all potential problems should be handled before the submit button is enabled,
              // we expect the submission of the previously successful changes to be a success.
              throw new TechnicalError('Unexpected failure of submission of previously validated changes', {
                context: {
                  subscriptionId,
                  step: stepId,
                  problem: result.problem,
                },
              })
            case 'already-signed':
              void navigate({
                to: '/contract/$subscriptionId',
                params: { subscriptionId: requestedSubscriptionId },
                replace: true,
              })
              return
            case 'user-needs-to-connect':
              void navigate({
                to: '/login',
                search: {
                  redirect: buildUrl({
                    to: '/subscribe/$subscriptionId/$stepId',
                    params: {
                      subscriptionId: requestedSubscriptionId,
                      stepId: requestedStepId,
                    },
                  }),
                },
              })
              return
            case 'no-subscription-found':
              crash(new NotFoundError())
              return
          }
        }

        const { nextStep } = result.output
        setLocalChanges({})
        setIsLoadingWhileTryCompleteStep(false)
        setCancelCompleteStepRetry(false)
        if (isEmbedInIframe && window.top !== null) {
          window.top.location.href = buildUrl({
            to: '/subscribe/$subscriptionId/$stepId',
            params: { subscriptionId, stepId: nextStep },
          })
        }
        void navigate({ to: '/subscribe/$subscriptionId/$stepId', params: { subscriptionId, stepId: nextStep } })
      },
      [
        localStateResult,
        completeStepTimeoutId,
        localChanges,
        completeStepMutate,
        isLoadingWhileTryCompleteStep,
        handleExclusionProblem,
        setLocalChanges,
        navigate,
        requestedSubscriptionId,
        requestedStepId,
        crash,
        isEmbedInIframe,
        buildUrl,
        operatingZone,
      ],
    )

    useEffect(() => {
      if (isLoadingWhileTryCompleteStep && !cancelCompleteStepRetry) {
        const interval = setTimeout(() => {
          handleSubmit()
        }, 1_000)

        if (!completeStepTimeoutId) {
          setCompleteStepTimeoutId(
            setTimeout(() => {
              console.error('Error context', { subscriptionId: requestedSubscriptionId, stepId: requestedStepId })
              crash(new TechnicalError('Timeout while trying to complete step'))
              // Our timeouts for APIs are 5 seconds on the back-end, so we add 1s for server-side processing and I/O
            }, 6_000),
          )
        }
        return () => clearTimeout(interval)
      }

      return undefined
    }, [
      cancelCompleteStepRetry,
      crash,
      handleSubmit,
      isLoadingWhileTryCompleteStep,
      completeStepTimeoutId,
      setCompleteStepTimeoutId,
      requestedSubscriptionId,
      requestedStepId,
    ])

    const goBackToPreviousStep = useAsyncCallback(async () => {
      const result = await trpc.selfOnboarding.getPreviousStep.query({
        subscriptionId: requestedSubscriptionId,
        stepId: requestedStepId,
      })
      if (isFailure(result)) {
        // if there are no previous steps, it means that we reached the beginning of the funnel
        void navigate({ to: '/search' })
        return
      }
      const previousStepId = result.output

      setLocalChanges({})

      void navigate({
        to: '/subscribe/$subscriptionId/$stepId',
        params: { subscriptionId: requestedSubscriptionId, stepId: previousStepId },
      })
    }, [requestedSubscriptionId, requestedStepId, setLocalChanges, navigate])

    useEffect(() => {
      const handlePopstate = () => {
        if (requestedStepId === 'rcda-company-data') setLocalChanges({})
      }

      window.addEventListener('popstate', handlePopstate)
    }, [setLocalChanges, requestedStepId])

    const goBackToBreadcrumbRootStep = useAsyncCallback(
      async (breadcrumb: SubscriptionBreadcrumb) => {
        const result = await trpc.selfOnboarding.getBreadcrumbRootStep.query({
          subscriptionId: requestedSubscriptionId,
          breadcrumb,
        })
        if (isFailure(result)) {
          return
        }
        const breadcrumbFirstStepId = result.output

        setLocalChanges({})

        void navigate({
          to: '/subscribe/$subscriptionId/$stepId',
          params: { subscriptionId: requestedSubscriptionId, stepId: breadcrumbFirstStepId },
        })
      },
      [requestedSubscriptionId, setLocalChanges, navigate],
    )

    const goBackToStepRoot = useCallback(() => {
      void navigate({
        to: '/subscribe/$subscriptionId/$stepId',
        params: { subscriptionId: requestedSubscriptionId, stepId: requestedStepId },
      })
    }, [navigate, requestedSubscriptionId, requestedStepId])

    const stepId = localStateResult.type === 'loading' ? requestedStepId : localStateResult.stepId
    const subscriptionId =
      localStateResult.type === 'loading' ? requestedSubscriptionId : localStateResult.subscriptionId
    const hubspotSubscriptionVersionId =
      localStateResult.type === 'loading' ? undefined : localStateResult.versionedSubscriptionId
    const serverValidationIssue = localStateResult.type === 'loading' ? null : localStateResult.serverValidationIssue
    const isStepComplete = localStateResult.type === 'loading' ? false : localStateResult.isStepComplete
    const localState = localStateResult.type === 'loading' ? undefined : localStateResult.localState
    const customerId = localStateResult.type === 'loading' ? undefined : localStateResult.customerId

    const nextEnabled = nextEnabledByAllContributions && isStepComplete && !serverValidationIssue

    const step = subscriptionUiStepsById[stepId]

    useEffect(() => {
      if (operatingZone) setDefaultLanguage(operatingZone)
    }, [operatingZone, setDefaultLanguage])

    if (operatingZone) ensureGTMLoaded(operatingZone)

    return (
      <SubscriptionV2StepPageContent
        subscriptionId={subscriptionId}
        customerId={customerId}
        versionedSubscriptionId={hubspotSubscriptionVersionId}
        step={step}
        stepId={stepId}
        localState={localState}
        updateAndPersistLocalChanges={updateAndPersistLocalChanges}
        updateLocalChanges={updateLocalChanges}
        handleSubmit={handleSubmit}
        registerNextEnabledContribution={registerNextEnabledContribution}
        nextEnabled={nextEnabled}
        serverValidationIssue={serverValidationIssue}
        goBackToPreviousStep={goBackToPreviousStep}
        goBackToBreadcrumbRootStep={goBackToBreadcrumbRootStep}
        goBackToStepRoot={goBackToStepRoot}
        detail={detail}
        synchronizing={localStateResult.type === 'loading' || localStateResult.serverCallPending}
        context="selfonboarding"
        isLoadingWhileTryCompleteStep={isLoadingWhileTryCompleteStep}
        setSubscriptionOwner={setSubscriptionOwner}
        changes={localChanges}
      />
    )
  },
)

function setSubscriptionOwner(customerId: string) {
  throw new TechnicalError('setSubscriptionOwner should not be called in the self-onboarding context', {
    context: { customerId },
  })
}

function useLocalState(
  requestedSubscriptionId: string,
  requestedStepId: SubscriptionStepId,
  localChanges: LooselyTypedData,
):
  | {
      type: 'nominal'
      localState: LooselyTypedData
      isStepComplete: boolean
      serverValidationIssue: SubscriptionStepValidationIssue | null
      serverCallPending: boolean
      subscriptionId: string
      customerId: string | undefined
      versionedSubscriptionId: string
      stepId: SubscriptionStepId
      operatingZone: OperatingZone
    }
  | { type: 'loading' } {
  const openChat = useOpenChat()
  const crash = useCrash()
  const navigate = useNavigate()
  const handleExclusionProblem = useHandleExclusionProblem()
  const { buildUrl } = useBuildUrl()

  // We opportunistically use useLocalState to update the current embedding partner implementation because :
  //   1 - It has access to the subscription state, which includes the embedding partner
  //   2 - The call already exists
  // This allows us to avoid adding a new call at the beginning of each session to handle an edge case (embeeded insurance
  // is an edge case).
  const setEmbeddingPartner = useSetEmbeddingPartner()

  // complete update description loaded and updated in the background from the server
  const [updateDescription, setUpdateDescription] = useSinglettonState(updateDescriptionSingleton)

  useEffect(() => {
    // When we are updating the state in the background during user input we keep don't display a loading state
    // so the UI doesn't blink at each click.
    // But when the subscriptionId or the stepId change after a navigation action, we must go back to loading
    // state to avoid displaying inconsistent state during step switching.
    setUpdateDescription(LOADING)
  }, [requestedSubscriptionId, requestedStepId, setUpdateDescription])

  const operatingZone = updateDescription.type === 'loading' ? undefined : updateDescription.operatingZone

  const apiResult = trpcReact.selfOnboarding.getStepUpdatePreview.useQuery({
    stepId: requestedStepId,
    subscriptionId: requestedSubscriptionId,
    changes: localChanges,
  })

  useEffect(() => {
    if (!apiResult.data || apiResult.isLoading) return
    const newServerResult = apiResult.data

    if (isFailure(newServerResult)) {
      const problem = newServerResult.problem
      switch (problem.type) {
        case 'exclusion':
          handleExclusionProblem({
            ...problem,
            operatingZone: checkDefinedAndNotNull(operatingZone),
            stepId: requestedStepId,
          })
          return
        case 'invalid-subscription-step-input':
          crash(new TechnicalError('Client sent invalid update request', { context: { message: problem.message } }))
          return
        case 'invalid-step':
          void navigate(
            requestedStepId
              ? {
                  to: '/subscribe/$subscriptionId/$stepId',
                  params: { subscriptionId: requestedSubscriptionId, stepId: problem.suggestedStep },
                }
              : { to: '/subscribe-no-possible-step' },
          )
          return
        case 'already-signed':
          void navigate({
            to: '/contract/$subscriptionId',
            params: { subscriptionId: requestedSubscriptionId },
            replace: true,
          })
          return
        case 'user-needs-to-connect':
          void navigate({
            to: '/login',
            search: {
              redirect: buildUrl({
                to: '/subscribe/$subscriptionId/$stepId',
                params: {
                  subscriptionId: requestedSubscriptionId,
                  stepId: requestedStepId,
                },
              }),
            },
            replace: true,
          })
          return
        case 'subscription-unavailable':
        case 'no-subscription-found':
          crash(
            new FunctionnalProblemError({
              title: 'Lien invalide',
              principalMessage: 'Le lien que vous avez suivi est invalide.',
              firstSubText:
                'Pour accéder à votre devis, utilisez le lien que vous avez reçu de la part de votre conseiller.',
              secondSubText: 'En cas de problème, contactez nous.',
              buttonText: 'Contacter Orus',
              onButtonClick: openChat,
            }),
          )
          return
        default:
          throw new UnreachableCaseError(problem)
      }
    }

    setEmbeddingPartner(newServerResult.output.embeddingPartner)

    const newUpdateDescription = { type: 'nominal' as const, ...newServerResult.output }
    if (!deepEqual(updateDescription, newUpdateDescription)) {
      setUpdateDescription(newUpdateDescription)
    }
  }, [
    crash,
    navigate,
    updateDescription,
    requestedSubscriptionId,
    requestedStepId,
    setUpdateDescription,
    setEmbeddingPartner,
    handleExclusionProblem,
    buildUrl,
    openChat,
    operatingZone,
    apiResult.data,
    apiResult.isLoading,
  ])

  if (updateDescription.type === 'loading') return updateDescription

  // To achieve maximal UI reactivity, we override the most recent state retreived from the server
  // in the background with the latest input change done by the user.

  // Note about server-side triggers :
  //   - Server-side triggers can change the inputs in the state
  //   - This could lead to inconsistencies if we are not careful
  //   - So when adding triggers we need to make sure that the UI and the triggers are designed
  // in such a way that changes done in the UI cannot be immediately overriden by a trigger.
  //   - We could avoid potential inconsistencies by cancelling user changes if an input is different
  // in the server output, but that is not a requirement right now, so it would be un-needed complexity
  // at this point
  const localState: LooselyTypedData = {
    ...updateDescription.stateAfter,
    ...localChanges,
  }

  const { isStepComplete, serverValidationIssue, subscriptionId, customerId, versionedSubscriptionId, stepId } =
    updateDescription

  return {
    type: 'nominal',
    localState,
    isStepComplete,
    serverValidationIssue,
    serverCallPending: apiResult.isLoading,
    subscriptionId,
    customerId,
    versionedSubscriptionId,
    stepId,
    operatingZone: updateDescription.operatingZone,
  }
}
